import React, { useState, useEffect } from "react";
import { Table, Input } from "antd";
import "./styles.scss";
import Shield from "../../assets/global/escudoWhite.png";
import Whatsapp from "../../assets/global/whatsappWhite.png";
import FaviconFox from "../../assets/global/FaviconFox.png";
import PartnerStore from "../PartnerStore";
import InformationCard from "../InformationCard";

import Papelaria from "../../assets/about-us/PapelariaItens.jpg";

function AboutUsBody() {
  return (
    <div className="w-full flex justify-center items-center bg-white text-black">
      <div className="limited flex w-full flex-col items-center">
        <section className="flex w-full flex-col md:flex-row items-center md:gap-10 gap-5 pt-[120px]">
          <img src={Papelaria} className="md:w-[50%] w-[100%] rounded-[15px]" alt="foto"/>
          <div className="w-full flex flex-col gap-5 md:w-[50%]">
          <p> A <b>Fox suprimentos</b> corporativos é a sua parceira confiável para soluções completas em material office, itens de limpeza e suprimentos para toda sua empresa. Com uma ampla variedade de produtos que vão desde materiais básicos de expediente até itens essenciais para manter o seu ambiente de trabalho limpo e organizado, nós oferecemos qualidade e praticidade para empresas de todos os tamanhos.
          </p>
          <p>
Nosso compromisso é proporcionar uma experiência de compra ágil e eficiente, garantindo que você encontre tudo o que precisa em um só lugar. Seja para reabastecer o estoque de papelaria, garantir a limpeza do seu escritório ou otimizar o dia a dia de trabalho, a Fox Suprimentos está aqui para atender suas necessidades com excelência.
</p>
<p>
Conte conosco para manter seu ambiente produtivo e organizado!
</p>
          </div>
        </section>

        <section className="flex w-full flex-col items-center md:gap-10 gap-5 pt-[100px] pb-[60px]">
          <div className="bg-orange px-4 flex justify-center items-center rounded-[8px] py-1 mb-5 md:mb-0">
            <b className="text-offwhite md:text-[45px] text-[29px] text-center md:text-start">
              MARCAS PARCEIRAS DA FOX
            </b>
          </div>
          <div className="w-full">
            <PartnerStore />
          </div>
        </section>

        <section className="flex w-full flex-col md:flex-row items-center md:gap-7 gap-6 py-[60px]">
          <div className="w-full">
            <InformationCard
              title="Qualidade garantida"
              text="Produtos de ótima qualidade"
              img={Shield}
              widthImg="60px"
            />
          </div>
          <div className="w-full">
            <InformationCard
              title="Loja oficial"
              text="Registrada e com certificado SSL"
              img={FaviconFox}
              widthImg="60px"
            />
          </div>
          <div className="w-full">
            <InformationCard
              title="Atendimento via Whatsapp"
              text="Segunda à sexta - 9h às 18h"
              img={Whatsapp}
              widthImg="80px"
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default AboutUsBody;
