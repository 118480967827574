import React from "react";
import portfolioData from "../../assets/portfolio/portfolio.pdf";

function Portfolio() {
  return (
    <div className="flex flex-col w-full h-screen">
      <object data={portfolioData} type="application/pdf" width="100%" height="100%">
        <p>Erro ao carregar Portfólio, por favor tente novamente mais tarde.</p>
      </object>
    </div>
  );
}

export default Portfolio;
