import React from "react";
import InformationCard from "../InformationCard";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import limpeza from "../../assets/home/05-icone-limpeza.png";
import expediente from "../../assets/home/06-icone-expediente.png";
import fotoLimpeza from "../../assets/home/09-limpeza.png";
import fotoExpediente from "../../assets/home/10-expediente.png";
import Shield from "../../assets/global/escudoWhite.png";
import Whatsapp from "../../assets/global/whatsappWhite.png";
import FaviconFox from "../../assets/global/FaviconFox.png";
import PartnerStore from "../PartnerStore";

function HomeBody() {
  const navigate = useNavigate();

  const goToProducts = (typeProduct) => {
    navigate(`/produtos?typeProduct=${typeProduct}`);
  };

  return (
    <div className="w-full flex justify-center items-center bg-white text-black">
      <div className="limited flex w-full flex-col items-center">
        <section className="flex w-full justify-around flex-col md:flex-row gap-5 md:pt-[80px] pt-[30px] pb-[60px]">
          <button onClick={() => goToProducts("limpeza")} className="button-home bg-orange">
            <img src={limpeza} className="md:w-[40px] w-[30px]" alt="limpeza" />
            <span className="text-offwhite">MATERIAL DE LIMPEZA</span>
          </button>
          <button onClick={() => goToProducts("expediente")} className="button-home bg-blue">
            <img src={expediente} className="md:w-[40px] w-[30px]" alt="expediente" />
            <span className="text-offwhite">MATERIAL DE EXPEDIENTE</span>
          </button>
        </section>

        <section className="flex w-full flex-col items-center md:gap-10 gap-5 pb-[60px]">
          <div className="bg-blue px-4  flex justify-center items-center rounded-[8px] py-1">
            <b className="text-offwhite md:text-[45px] text-[29px]">SOBRE A FOX</b>
          </div>
          <span className="text-center">
          A fox suprimentos é uma empresa dedicada a fornecer facilidade e agilidade para sua empresa na compra dos suprimentos corporativos. Empresa fundada em 2019 na cidade de Recife-PE tem como base sua confiança e respeito no mundo dos negócios atuando sempre com rapidez e agilidade para fornecer todos os tipos de suprimentos. Conseguindo fazer assim relações duradouras com todos os seus clientes.
          </span>
        </section>

        <section className="flex w-full flex-col md:flex-row md:justify-between items-center mb-20 md:mb-0">
          <div className="md:w-[50%] w-[100%] flex justify-center">
            <img src={fotoLimpeza} className="w-[70%]" alt="limpeza" />
          </div>
          <div className="flex flex-col md:w-[50%] gap-5 items-center md:items-start">
            <b className="text-blue md:text-[45px] text-[29px] leading-[50px] text-center md:text-start md:pr-[200px] mt-10 md:mt-0">
              MATERIAL DE LIMPEZA
            </b>
            <span className="text-center md:text-start md:pr-[60px]">
            Oferecemos uma linha completa de produtos de limpeza que garantem ambientes mais saudáveis e organizados. De desinfetantes a detergentes, nossos itens são eficazes e sustentáveis, atendendo às exigências do mercado atual.
            </span>
            <button onClick={() => goToProducts("limpeza")} className="bg-blue text-offwhite text-[21px] py-[8px] px-[25px] rounded-[25px] md:w-[40%]">
              <span>VER PRODUTOS</span>
            </button>
          </div>
        </section>

        <section className="flex w-full flex-col-reverse md:flex-row md:justify-between items-center">
          <div className="flex flex-col md:w-[50%] gap-5 items-center md:items-start">
            <b className="text-blue md:text-[45px] text-[29px] md:leading-[50px] leading-[30px] text-center md:text-start md:pr-[20px] mt-10 md:mt-0">
              MATERIAL DE EXPEDIENTE
            </b>
            <span className="text-center md:text-start md:pr-[60px]">
            Nossa seleção de material de expediente abrange tudo o que sua empresa precisa para funcionar com eficiência. De papelaria a ferramentas de escritório, priorizamos qualidade e praticidade, ajudando você a manter a produtividade no dia a dia.
            </span>
            <button onClick={() => goToProducts("expediente")} className="bg-blue text-offwhite text-[21px] py-[8px] px-[25px] rounded-[25px] md:w-[40%]">
              <span>VER PRODUTOS</span>
            </button>
          </div>
          <div className="md:w-[50%] w-[100%] flex justify-center">
            <img src={fotoExpediente} className="w-[70%]" alt="limpeza" />
          </div>
        </section>

        <section className="flex w-full flex-col items-center md:gap-10 gap-5 pt-[120px] pb-[60px]">
          <div className="bg-orange px-4 flex justify-center items-center rounded-[8px] py-1 mb-5 md:mb-0">
            <b className="text-offwhite md:text-[45px] text-[29px] text-center md:text-start">
              MARCAS PARCEIRAS DA FOX
            </b>
          </div>
          <div className="w-full">
            <PartnerStore />
          </div>
        </section>

        <section className="flex w-full flex-col md:flex-row items-center md:gap-7 gap-6 py-[60px]">
          <div className="w-full">
            <InformationCard
              title="Qualidade garantida"
              text="Produtos de ótima qualidade"
              img={Shield}
              widthImg="60px"
            />
          </div>
          <div className="w-full">
            <InformationCard
              title="Loja oficial"
              text="Registrada e com certificado SSL"
              img={FaviconFox}
              widthImg="60px"
            />
          </div>
          <div className="w-full">
            <InformationCard
              title="Atendimento via Whatsapp"
              text="Segunda à sexta - 9h às 18h"
              img={Whatsapp}
              widthImg="80px"
            />
          </div>
        </section>
      </div>
    </div>
  );
}

export default HomeBody;
