import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import axios from "axios";
import { List, Spin, Typography } from "antd";

function ReceiptBody() {
  const location = useLocation(); // Hook para acessar a URL atual e query string
  const queryParams = new URLSearchParams(location.search);
  const pedidoId = queryParams.get("p") || ""; // Define o tipo de produto inicial com base na query string

  const [itensPedido, setItensPedido] = useState([]);

  const fetchData = async () => {
    if (pedidoId !== ""){
    try {
      const pedidoResponse = await axios.get(`https://foxsuprimentos.com.br/api/pedido?p=${pedidoId}`, {
        headers: {
          Authorization: "Basic RGVzZW52b2x2aW1lbnRvRm94OjEyM0BGb3hEZXY=",
        },
      });

      var itens = pedidoResponse.data.pedido.split("#$")
      // var pedidoJson = JSON.parse(pedidoResponse.data.pedido)
      setItensPedido(itens)
      console.log(itensPedido)

      
    } catch (error) {
        console.log(error)
        alert("pedido não encontrado")
    }
    }else {
        alert("pedido inválido")
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
   
    <div className="w-full min-h-96 flex justify-center items-center bg-white text-black">
         {itensPedido.length ? (
          <div className="w-full flex flex-col m-20">  
    <h1 className="text-big font-bold">
      Itens do pedido
    </h1>
    { itensPedido.map(d => d && (<li className="text-base"> {d} </li>)) }
    </div>
    )
     : 
    (
        <div className="flex flex-col mt-[20px]">
          <Spin tip="Carregando" size="large" />
          <span className="mt-[5px]">Carregando...</span>
        </div>
      )
         }
         </div>
  );
}

export default ReceiptBody;
