import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import ReceiptBody from "../../components/ReceiptBody";

function Receipts() {
  return (
    <div className="flex flex-col w-full">
      <Header />
      <ReceiptBody />
      <Footer />
    </div>
  );
}

export default Receipts;
