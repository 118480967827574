import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import HomeBody from "../../components/HomeBody";
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/ScrollTotop";

function Home() {
  return (
    <div className="flex flex-col w-full">
      <ScrollToTop/>
      <Header />
      <Banner local="home" height={{ mobile: "70vh", desktop: "37vw" }} />
      <HomeBody />
      <Footer />
    </div>
  );
}

export default Home;
