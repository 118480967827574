import React, { useState, useEffect } from "react";
import bgFooter from "../../assets/footer/bg-footer.png";
import logo from "../../assets/global/logoFox.png";
import email from "../../assets/footer/21-email.png";
import whatsapp from "../../assets/footer/22-whatsapp.png";
import WhatsappRedirect from "../WhatsappRedirect";
import insta from "../../assets/footer/23-insta.png";
import insta2 from "../../assets/footer/20-insta.png";
import facebook from "../../assets/footer/24-facebook.png";

import { Link } from "react-router-dom";

import "./styles.scss";

function Footer() {

  const handleContactClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      const contatoElement = document.getElementById("contato");
      if (contatoElement) {
        contatoElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  
  return (
    <div id="contato">
      <div
        className="w-full flex justify-center md:h-[50vh] bg-cover bg-center bg-no-repeat rounded-t-[50px] "
        style={{ backgroundImage: `url(${bgFooter})` }}
      >
        <div className="flex flex-col md:w-[75%] w-[90%] gap-10 md:gap-0 md:flex-row justify-between items-center text-white py-10 md:py-0">
          <div className="flex flex-col gap-5 items-center md:items-start">
            <img src={logo} className="social w-[150px] md:w-[180px] mb-5" alt="logo" />
            {/* <div className="flex gap-3">
              <img src={insta2} className="social w-[20px] h-[20px] md:h-[25px] md:w-[25px]" alt="insta" />
              <a className="hover:text-orange" href="#" target="_blank">
                @fox.papelaria
              </a>
            </div> */}
            <div className="flex gap-3 items-center">
              <img src={email} className="social w-[20px] md:w-[25px] md:h-[20px]" alt="insta" />
              <a className="hover:text-orange" href="#" target="_blank">
                comercial@foxsuprimentos.com.br
              </a>
            </div>
            <div className="flex">
              <a href="#" target="_blank" className="hover:text-orange">
                Rua Ramiro Costa, 156 Recife - PE
              </a>
            </div>
          </div>

          <div className="flex flex-col gap-8 mt-5 md:mt-0">
            <div className="flex flex-col gap-3">
              <b className="text-center md:text-start">Dúvidas? Fale Conosco</b>
              <button onClick={WhatsappRedirect} className="flex items-center gap-4 bg-orange text-offwhite text-[21px] py-[8px] px-[20px] rounded-[15px] md:w-[100%]">
                <img src={whatsapp} className="social w-[20px] md:w-[25px]" alt="whatsapp" />
                <span>(81) 9.8859-2115</span>
              </button>
            </div>
            {/* <div className="flex flex-col gap-3">
              <b className="text-center md:text-start">Mídias Sociais</b>
              <div className="flex gap-2 justify-center md:justify-start">
                <a href="#" target="_blank">
                  <img src={insta} className="social w-[35px] md:w-[35px] growth" alt="insta" />
                </a>
                <a href="#" target="_blank">
                  <img src={facebook} className="social w-[35px] md:w-[35px] growth" alt="facebook" />
                </a>
              </div>
            </div> */}
          </div>

          <div className="flex flex-col gap-3">
            <b>Mapa do Site</b>
            <ul className="flex flex-col gap-4 items-center">
              <li>
                <Link to="/">Início</Link>
              </li>
              <li>
                <Link to="/produtos">Produtos</Link>
              </li>
              <li>
                <Link to="/sobre-nos">Sobre Nós</Link>
              </li>
              <li>
                <a href="/contato" onClick={handleContactClick}>
                  Contato
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex w-full bg-orange justify-center items-center py-1 font-[300]">
        <span className="text-center text-offwhite text-[15px]">
          Copyright 2024 - Box distribuidora de embalagens LTDA
        </span>
      </div>
    </div>
  );
}

export default Footer;
