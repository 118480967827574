import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Foroni from "../../assets/partners/11-marca-foroni.png";
import Raid from "../../assets/partners/12-marca-raid.png";
import Cis from "../../assets/partners/13-marca-cis.png";
import Bralimpia from "../../assets/partners/14-marca-bralimpia.png";
import Pilot from "../../assets/partners/15-marca-pilot.png";
import Bic from "../../assets/partners/16-marca-bic.png";

import "./styles.scss";

function PartnerStore() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
  };

  return (
    <>
      {/* Desktop View */}
      <div className="isDesktop w-full flex gap-[55px] items-center py-5">
        <img src={Foroni} alt="top" className="w-[180px] h-[60px]" />
        <img src={Raid} alt="top" className="w-[120px]" />
        <img src={Cis} alt="top" className="w-[120px]" />
        <img src={Bralimpia} alt="top" className="w-[190px]" />
        <img src={Pilot} alt="top" className="w-[190px]" />
        <img src={Bic} alt="top" className="w-[160px]" />
      </div>

      {/* Mobile View */}
      <div className="w-full isMobile">
        <Slider {...settings}>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "200px",
              }}
            >
              <img src={Foroni} alt="top" className="w-[140px] h-[45px]" />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "200px",
              }}
            >
              <img src={Raid} alt="top" className="w-[85px]" />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "200px",
              }}
            >
              <img src={Cis} alt="top" className="w-[100px]" />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "200px",
              }}
            >
              <img src={Bralimpia} alt="top" className="w-[160px]" />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "200px", 
              }}
            >
              <img src={Pilot} alt="top" className="w-[160px]" />
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "200px", 
              }}
            >
              <img src={Bic} alt="top" className="w-[120px]" />
            </div>
          </div>
        </Slider>
      </div>
    </>
  );
}

export default PartnerStore;
