import React, { useState, useEffect, useRef } from "react";
import CartIcon from "../../assets/products/trolley.png";
import Trash from "../../assets/products/trash (2).png";
import Whatsapp from "../../assets/global/whatsappWhite.png";
import { Spin } from "antd";
import "./styles.scss";

function FixedCart({ selectedItems, removeItem, finalizeOrder, sendingOrder, handleChangeQuantity }) {
  const [isOpen, setIsOpen] = useState(false);
  const cartRef = useRef(null);

  const toggleCart = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (cartRef.current && !cartRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div ref={cartRef}>
      {/* Botão flutuante */}
      <button className="fixed-cart-btn" onClick={toggleCart}>
        <img src={CartIcon} className="md:w-[40px] w-[35px]" alt="Cart" />
        {/* Bolinha com a contagem de itens */}
        {selectedItems.length > 0 && <span className="cart-badge">{selectedItems.length}</span>}
      </button>

      {/* Menu lateral */}
      <div
        className={`fixed-cart-menu ${isOpen ? "open" : ""}`}
        style={{
          display: isOpen ? "block" : "none",
        }}
      >
        <div className="flex justify-between items-center p-4">
          <span className="text-white font-bold">Total: {selectedItems.length}</span>
          <button className="text-white text-2xl" onClick={toggleCart}>
            X
          </button>
        </div>

        {/* Lista de itens selecionados */}
        <ul className="flex flex-col gap-2">
          {selectedItems.length > 0 ? (
            <div className="flex-col">
              {selectedItems.map((item, index) => (
                <li key={index} className="p-2 border-b text-white w-full">
                  {/* Exibindo o nome do produto em vez do ID */}
                  <div className="w-full flex justify-between items-center">
                    <div className="flex flex-col w-full">
                      <span className="text-start text-[16px] w-[90%]">{item.nome}</span>
                      <div className="flex items-center gap-2 text-[16px]">
                        <span>Quantidade: </span>
                        <input
                          className="text-blue w-[70px] pl-[4px]"
                          type="number"
                          min="1"
                          value={item.quantidade}
                          onChange={(e) => handleChangeQuantity(item, e.target.value)}
                        ></input>
                        <span>unidade(s)</span>
                      </div>
                    </div>

                    <button onClick={() => removeItem(item.key)}>
                      <img src={Trash} className="md:w-[18px] md:h-[18px] w-[20px] h-[20px]" alt="Remove" />
                    </button>
                  </div>
                </li>
              ))}
              {sendingOrder ? (
                <div className="flex flex-col mt-[20px]">
                  <Spin tip="Carregando" size="large" />
                  <span className="mt-[5px] text-center text-white">Enviando seu pedido...</span>
                </div>
              ) : (
                <button
                  onClick={finalizeOrder}
                  className="mt-[10px] flex items-center justify-center rounded-[25px] py-3 px-10 text-[18px] bg-[#51CB5F] gap-2 w-full"
                >
                  <img src={Whatsapp} className="w-[25px]" alt="whatsapp" />
                  <span className="text-offwhite">Finalizar Pedido</span>
                </button>
              )}
            </div>
          ) : (
            <li className="text-white p-2">Nenhum item selecionado</li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default FixedCart;
