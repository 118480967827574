import React, { useState, useEffect } from "react";
import bannerHomeDesktop from "../../assets/banners/39-novo-banner-pc.png";
import bannerHomeMobile from "../../assets/banners/38-novo-banner-mobile.png";
import bannerProductsDesktop from "../../assets/banners/34-banner-produtos.png";
import bannerProductsMobile from "../../assets/banners/35-banner-produtos-mobile.png";
import bannerAboutUsDesktop from "../../assets/banners/36-banner-sobre.png";
import bannerAboutUsMobile from "../../assets/banners/37-banner-sobre-mobile.png";

import "./styles.scss";

function Banner({ local = "", height = { mobile: "70vh", desktop: "520px" } }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const choiceImage = () => {
    if (isMobile) {
      switch (local) {
        case "home":
          return bannerHomeMobile;
        case "products":
          return bannerProductsMobile;
        case "aboutus":
          return bannerAboutUsMobile;
        default:
          return bannerHomeMobile;
      }
    }
    switch (local) {
      case "home":
        return bannerHomeDesktop;
      case "products":
        return bannerProductsDesktop;
      case "aboutus":
        return bannerAboutUsDesktop;
      default:
        return bannerHomeDesktop;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="w-full bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(${choiceImage()})`,
        height: isMobile ? height.mobile : height.desktop,
      }}
    ></div>
  );
}

export default Banner;
