import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom"; // Importa o Link do react-router-dom
import "./styles.scss";

function HamburgerMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const handleContactClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      const contatoElement = document.getElementById("contato");
      if (contatoElement) {
        contatoElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
    closeMenu();
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <div ref={menuRef}>
      <button className="hamburger block md:hidden focus:outline-none" onClick={toggleMenu}>
        <div className={`hamburger-line ${isOpen ? "open" : ""}`}></div>
        <div className={`hamburger-line ${isOpen ? "open" : ""}`}></div>
        <div className={`hamburger-line ${isOpen ? "open" : ""}`}></div>
      </button>
      <nav
        className="mobile-menu md:block"
        style={{
          display: isOpen ? "block" : "none",
        }}
      >
        <div className="flex justify-between items-center p-4">
          <span className="text-white font-bold">Menu</span>
          <button className="text-white text-2xl" onClick={toggleMenu}>
            X
          </button>
        </div>
        <ul className="flex flex-col md:flex-row">
          <li className="p-2 border-b md:border-b-0 md:border-r">
            <Link to="/" onClick={closeMenu}>
              Início
            </Link>
          </li>
          <li className="p-4 border-b md:border-b-0 md:border-r">
            <Link to="/produtos" onClick={closeMenu}>
              Produtos
            </Link>
          </li>
          <li className="p-4 border-b md:border-b-0 md:border-r">
            <Link to="/sobre-nos" onClick={closeMenu}>
              Sobre Nós
            </Link>
          </li>
          <li className="p-4">
            <a href="/contato" onClick={handleContactClick}>
              Contato
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
}

export default HamburgerMenu;
