import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import ProductsBody from "../../components/ProductsBody";
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/ScrollTotop";

function Products() {
  return (
    <div className="flex flex-col w-full">
      <ScrollToTop />
      <Header />
      <Banner local="products" height={{ mobile: "25vh", desktop: "25vw" }} />
      <ProductsBody />
      <Footer />
    </div>
  );
}

export default Products;
