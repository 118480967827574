import React from "react";
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import AboutUsBody from "../../components/AboutUsBody";
import Footer from "../../components/Footer";
import ScrollToTop from "../../components/ScrollTotop";

function AboutUs() {
  return (
    <div className="flex flex-col w-full">
      <ScrollToTop />
      <Header />
      <Banner local="aboutus" height={{ mobile: "25vh", desktop: "25vw" }} />
      <AboutUsBody />
      <Footer />
    </div>
  );
}

export default AboutUs;
