import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import WhatsappRedirect from "../WhatsappRedirect";
import HamburgerMenu from "../HamburgerMenu";
import Whatsapp from "../../assets/global/whatsappWhite.png";
import logo from "../../assets/global/logoFox.png";
import facebook from "../../assets/global/facebookFox.png";
import insta from "../../assets/global/instaFox.png";
import whatsapp from "../../assets/global/whatsappFox.png";

function Header() {
  const handleContactClick = (e) => {
    e.preventDefault();
    setTimeout(() => {
      const contatoElement = document.getElementById("contato");
      if (contatoElement) {
        contatoElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <div className="w-full flex flex-col">
      <div className="w-full flex justify-center items-center h-[50px] md:h-[70px] bg-offwhite text-blue">
        <a href="mailto:comercial@foxsuprimentos.com.br">comercial@foxsuprimentos.com.br</a>
      </div>
      <div className="w-full flex justify-center items-center bg-blue text-white h-[80px] md:h-[100px]">
        <div className="limited flex w-full items-center justify-between">
          <div>
            <Link to="/">
              <img src={logo} className="social w-[100px] md:w-[130px]" alt="logo" />
            </Link>
          </div>
          <div>
            <div className="isMobile">
              <HamburgerMenu />
            </div>
            <div className="isDesktop">
              <ul>
                <li>
                  <Link to="/">Início</Link>
                </li>
                <li>
                  <Link to="/produtos">Produtos</Link>
                </li>
                <li>
                  <Link to="/sobre-nos">Sobre Nós</Link>
                </li>
                <li>
                  <Link to="/portfolio">Portfólio</Link>
                </li>
                <li>
                  <a href="/contato" onClick={handleContactClick}>
                    Contato
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <button onClick={WhatsappRedirect} className="flex justify-center items-center  rounded-[18px] md:rounded-[25px] py-2 md:px-8 px-3 md:text-[17px] text-[16px] bg-[#52CA5F] gap-1 md:gap-2">
            <img src={Whatsapp} className="md:w-[20px] w-[14px]" alt="whatsapp" />
            <span className="text-offwhite">(81) 9.8859-2115</span>
          </button>
          {/* <div className="flex gap-2 md:gap-4">
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={whatsapp} className="social md:w-[35px] w-[30px] growth" alt="whatsapp" />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={insta} className="social md:w-[35px] w-[30px] growth" alt="instagram" />
            </a>
            <a href="#" target="_blank" rel="noopener noreferrer">
              <img src={facebook} className="social md:w-[35px] w-[30px] growth" alt="facebook" />
            </a>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default Header;
