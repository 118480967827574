import React from "react";

import "./styles.scss";

function InformationCard({ title, text, img, widthImg }) {
  return (
    <div className="w-full flex gap-5 border-[1px] border-blue border-solid rounded-l-[100px] rounded-r-[35px] bg-[#F9F9F9]">
      <div className="flex justify-center items-center bg-blue rounded-full p-5">
        <img src={img} alt="img" style={{ width: `${widthImg}` }} />
      </div>
      <div className="flex flex-col gap-2 justify-center pr-5">
        <span className="font-bold text-[24px] text-orange leading-[26px]">{title}</span>
        <span className="font-normal text-blue text-[15px] leading-[16px]">{text}</span>
      </div>
    </div>
  );
}

export default InformationCard;
